class BackgroundVideo extends HTMLElement {
  constructor () {
    super()

    this.video = this.querySelector('video')
    this.pauseButton = this.querySelector('background-video-pause-button')

    this.video.addEventListener('canplay', () => {
      this.video.classList.remove('opacity-0')
    })
  }

  pause () {
    this.video.pause()
    this.pauseButton.classList.remove('play')
    this.pauseButton.classList.add('pause')
    this.pauseButton.setAttribute('data-playing', 'false')
    this.pauseButton.setAttribute('aria-label', this.pauseButton.getAttribute('data-play'))
  }

  resume () {
    this.video.play()
    this.pauseButton.classList.remove('pause')
    this.pauseButton.classList.add('play')
    this.pauseButton.setAttribute('data-playing', 'true')
    this.pauseButton.setAttribute('aria-label', this.pauseButton.getAttribute('data-pause'))
  }
}

customElements.define('background-video', BackgroundVideo)

class BackgroundVideoPauseButton extends HTMLElement {
  constructor () {
    super()

    this.bgVideo = this.closest('background-video')
    this.videoPauseInViewport = false

    const toggleVideo = (pause = true) => {
      if (pause) {
        this.bgVideo.pause()
      } else {
        this.bgVideo.resume()
      }
    }

    // Play video when top of video hits site-header
    const checkVideoPlay = (elem) => {
      const rect = elem.getBoundingClientRect()
      const siteHeader = document.querySelector('[data-section="header"]')
      const headerHeight = siteHeader ? siteHeader.clientHeight : 0
      return rect.top <= headerHeight + 3 & rect.top > -200
    }

    this.addEventListener('click', () => {
      toggleVideo(!this.bgVideo.video.paused)
      if (this.videoPauseInViewport) {
        this.videoPauseInViewport = false
      }
      if (this.dataset.playInViewport && checkVideoPlay(this.bgVideo) && this.bgVideo.video.paused) {
        this.videoPauseInViewport = true
      }
    })

    if (this.dataset.playInViewport) {
      toggleVideo(!checkVideoPlay(this.bgVideo))
      window.addEventListener('scroll',  (event) => {
        if (!this.videoPauseInViewport) {
          this.bgVideo.resume()
        }
      }, { passive: true })
    }
  }
}

customElements.define('background-video-pause-button', BackgroundVideoPauseButton)
